import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { connect, getWeb3Modal } from "./redux/blockchain/blockchainActions";
import Router from "./routes";

import AOS from "aos";
import "aos/dist/aos.css";

export default function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  useEffect(() => {
    const connectWallet = async () => {
      const web3Modal = await getWeb3Modal();
      // connect automatically and without a popup if user is already connected
      if (web3Modal && web3Modal.cachedProvider) {
        dispatch(connect());
      }
    };

    connectWallet();
  }, []);

  return (
    <>
      <Router />
    </>
  );
}
