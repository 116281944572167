import React from "react";

export default function LoadingOverlay({ active }) {
  return (
    active && (
      <div className="loading-container">
        <div className="loading-text">Loading...</div>
      </div>
    )
  );
}
