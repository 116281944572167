export const ORIG_CONTRACT_ADDRESS =
  "0x7D5f8F9560103E1ad958A6Ca43d49F954055340a";
export const GENESIS_CONTRACT_ADDRESS =
  "0x0b289dEa4DCb07b8932436C2BA78bA09Fbd34C44";
export const GENESIS_3D_CONTRACT_ADDRESS =
  "0xe02A74813053e96C5C98F817C0949E0B00728Ef6";
export const TOKEN_CONTRACT_ADDRESS =
  "0xcf639e01bCDAe12c5405fe575B60499107A6B4FC";
export const PROXY_CONTRACT_ADDRESS =
  "0xa12F94C29FEf808A854c3FE44555c4303f8F9963";

export const PAUSED = true;
export const PRESALE = true;
