import * as OLD_CONFIG from "../assets/config";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  connect,
  fetchUserBalances,
  setLoading,
} from "../redux/blockchain/blockchainActions";
// import { walletAddressShort } from "utils/helper";
import AButton from "../components/AButton";

import AOS from "aos";
import "aos/dist/aos.css";

export default function Weirdification() {
  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

  const {
    connected,
    address,
    tokenContract,
    genesisApesContract: newApesContract,
    oldApesContract,
    tokenPendingBalance: pending,
  } = blockchain;
  //const addressShort = walletAddressShort(address);

  const [authorized, setAuthorized] = useState(false);
  const [oldApesBalance, setOldApesBalance] = useState(0);

  async function authorize() {
    try {
      dispatch(setLoading(true));
      await oldApesContract.methods
        .setApprovalForAll(OLD_CONFIG.GENESIS_CONTRACT_ADDRESS, true)
        .send({
          gasLimit: 285000,
          to: OLD_CONFIG.ORIG_CONTRACT_ADDRESS,
          from: address,
          value: 0,
        });
      await checkIsAuthorized();
      await getOldApesBalance();
    } catch (e) {
      // console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  }
  async function checkIsAuthorized() {
    try {
      const data = await oldApesContract.methods
        .isApprovedForAll(address, OLD_CONFIG.GENESIS_CONTRACT_ADDRESS)
        .call({ from: address });
      setAuthorized(data);
    } catch (e) {
      // console.log(e);
    }
  }
  async function migrate() {
    try {
      dispatch(setLoading(true));
      if (pending > 0) {
        await tokenContract.methods.getReward().send({
          to: OLD_CONFIG.GENESIS_CONTRACT_ADDRESS,
          from: address,
          value: 0,
        });
      }
      dispatch(fetchUserBalances());

      await newApesContract.methods.evolveAllApes(1000).send({
        to: OLD_CONFIG.GENESIS_CONTRACT_ADDRESS,
        from: address,
        value: 0,
      });
      await getOldApesBalance();
    } catch (e) {
      // console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  }
  async function getOldApesBalance() {
    try {
      const response = await newApesContract.methods
        .getBalanceOfOldApes()
        .call({ from: address });
      setOldApesBalance(+response);
    } catch (e) {
      // console.log(e);
    }
  }

  async function onConnected() {
    try {
      dispatch(setLoading(true));
      await checkIsAuthorized();
      await getOldApesBalance();
    } catch (e) {
      // console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  }
  useEffect(() => {
    if (address && oldApesContract) onConnected();
  }, [address, oldApesContract]);

  const button = () => {
    if (!connected) {
      return (
        <AButton
          text="Connect"
          onClick={async (e) => {
            e.preventDefault();
            dispatch(connect());
          }}
        />
      );
    } else if (!oldApesBalance) {
      return <AButton text="Migration complete!" disabled />;
    } else if (!authorized) {
      return (
        <AButton
          text="Authorize"
          onClick={async (e) => {
            e.preventDefault();
            authorize();
          }}
        />
      );
    } else {
      return (
        <>
          <AButton
            text={
              oldApesBalance == 1
                ? "Migrate 1 ape"
                : "Migrate " + oldApesBalance + " apes"
            }
            onClick={migrate}
          />
          {oldApesBalance != oldApesBalance && (
            <div className="text-center">
              <p className="para mb-0 mt-2">
                Your balance is {oldApesBalance} but only
                {oldApesBalance} will be migrated in this transaction.
              </p>
              <p className="para mb-0">
                In order to migrate the remaining
                {+oldApesBalance - oldApesBalance} you will need to sign a new
                transaction.
              </p>
              <p className="para mb-0">
                This is for avoiding running out of gas.
              </p>
            </div>
          )}
        </>
      );
    }
  };

  return (
    <>
      <section className="position-relative open_overlay-sections_none">
        <img
          src="/assets/img/Ellipse_9.png"
          className="position-absolute geneticsLeft_img"
        />
        <div className="container pt-sm-5 pt-4 z-index-100">
          <div
            className="text-center mb-3 mb-sm-4"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <h2 className="main-heading">WEIRDIFICATION</h2>
          </div>

          <div
            className="row d-flex justify-content-center mb-5"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="col-md-10 col-16 text-center">
              <div>
                <h3 className="sub-heading">
                  Ready for change? Weird Apes are always ready!
                </h3>
                <p className="para">
                  Our journey together is about to begin, and it will hold a lot
                  of interesting and weird surprises for us. Weird Apes are
                  smart animals and are prone to change &quot;The most difficult
                  task in life is to change yourself.&quot;
                </p>
                <h3 className="sub-heading">What does change mean?</h3>
                <p className="para">
                  The Weird Apes after settling in the CronosJungle realized
                  that inertia would get them nowhere. Work must be done to
                  build the CronosJungle, how? By clicking on
                  &quot;Migrate&quot; your Weird Apes will be able to work and
                  get $WAC daily.They will also be able to change their name and
                  add a phantasmagorical biography and that&apos;s not all, with
                  migration our Weird Apes will finally be able to breed. The
                  CronosJungle population is starting now.
                </p>
              </div>
            </div>
          </div>

          <div
            className="row z-index-100"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="col-lg-12 justify-content-center align-items-center mt-5 mb-5">
              <div className="text-center">
                <img
                  src="/assets/img/Migratebanner.png"
                  className="w-100 w-md-75 d-block d-lg-none"
                  alt="Breed image"
                />
                <img
                  src="/assets/img/Migratebanner.png"
                  className="w-100 w-md-75 d-none d-lg-block"
                  alt="Breed image"
                />
              </div>
            </div>
          </div>

          <div
            className="row d-flex justify-content-center mb-5"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="col-md-10 col-16 text-center">
              <h3 className="sub-heading">Why migrate?</h3>
              <p className="para mb-0">
                Migrated Weird Apes will be able to earn $8WAC per day. The $WAC
                will be used to change the name and lore of your Weird Apes, and
                at the same time will be used for breeding. Breeding will allow
                you to get an incubator, which you can trade as is or you can
                hatch it and get a Baby Weird Ape. The CronosJungle population
                is starting now!
              </p>
            </div>
          </div>
        </div>

        <div
          className="row d-flex justify-content-center mb-5"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <div className="col-md-10 col-16 text-center">
            <h3 className="sub-heading">How to migrate?</h3>
            <p className="para mb-0">
              Very easy! The migration process is divided into two main steps
              that you can achieve right here from this page.
              <br />
              <br />
              1. Click the AUTHORIZE button, it will initialize the migration
              process. You will be asked to sign a transaction, after that you
              are ready to migrate.
              <br />
              <br />
              2. Click the MIGRATE button to complete the process, also this
              time it will be asked to sign a transaction, once you have signed
              your migration <br />
              is complete you can benefit from the beauty of evolution.
            </p>
          </div>
        </div>

        <div className="container pt-sm-5 pt-4 z-index-100" id="migrate">
          <div
            className="text-center mb-3 mb-sm-4"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            {button()}
          </div>
        </div>
      </section>

      <footer className="mt-2 mt-md-2 pt-md-2 open_overlay-sections_none">
        <section className="faq-section-bg position-relative py-sm-5 mb-5">
          <div className="container mb-5 py-3 z-index-100">
            <div className="row justify-content-center">
              <div data-aos="fade-up" data-aos-duration="3000">
                <h1 className="main-heading text-center mb-4 mb-md-5"></h1>
              </div>
              <div className="col-lg-9">
                <div
                  className="row z-index-100"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div
                    className="
                  col-lg-12
                  justify-content-center
                  align-items-center
                  mt-5
                  mb-5
                "
                  >
                    <div className="text-center">
                      <img
                        src="/assets/img/coin.png"
                        className="w-100 w-md-75 d-block d-lg-none"
                        alt="Breed image"
                      />
                      <img
                        src="/assets/img/coin.png"
                        className="w-100 w-md-75 d-none d-lg-block"
                        alt="Breed image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
}
