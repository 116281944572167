/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import AOS from "aos";
import "aos/dist/aos.css";

export default function Navbar() {
  const { pathname } = useLocation();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  function toggleMobileMenu() {
    if (menuIsOpen) {
      closeMenuMobile();
    } else {
      openMenuMobile();
    }
  }
  function openMenuMobile() {
    setMenuIsOpen(true);
    // Not a good practice to use JQuery here
    $("body").addClass("fix-screen-mobile");
    $(".main-top-section").addClass("mobile-view");
    $("#customize__container").removeClass("z-index-100");
    $("#lady__min__container").removeClass("z-index-100");
    $("#voxel__boxes__container").removeClass("z-index-100");
  }
  function closeMenuMobile() {
    setMenuIsOpen(false);
    // Not a good practice to use JQuery here
    $("body").removeClass("fix-screen-mobile");
    $(".main-top-section").removeClass("mobile-view");
    $("#customize__container").addClass("z-index-100");
    $("#lady__min__container").addClass("z-index-100");
    $("#voxel__boxes__container").addClass("z-index-100");
  }

  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  useEffect(() => {
    // Not a good practice to use JQuery here
    $("body").removeClass("fix-screen-mobile");
    $(".main-top-section").removeClass("mobile-view");
    $(".hamburger-menu").removeClass("animate");
  }, [pathname]);

  return (
    <>
      <section className="z-index-100 py-2">
        <nav className="navbar-wrapper d-flex bg-nav py-2 py-xl-0">
          <div id="overlay" className={(menuIsOpen && "overlay-active") || ""}>
            <div className="container navbar-container">
              <div className="row">
                <div className="col-12">
                  <div
                    className="
                    left
                    d-flex
                    justify-content-center
                    text-center
                    flex-column
                    align-items-center
                  "
                  >
                    <ul className="list-unstyled pt-5">
                      <li className="py-xl-3 py-2 overflow-hidden">
                        <span className="d-inline-block section-1">
                          <Link className="overlay-links text-white" to="/">
                            Home
                          </Link>
                        </span>
                      </li>
                      <li className="py-xl-3 py-2 overflow-hidden">
                        <span className="d-inline-block section-5">
                          <HashLink
                            to="/#FAQs"
                            className="overlay-links text-white"
                          >
                            FAQs
                          </HashLink>
                        </span>
                      </li>

                      {/* START */}

                      <li className="py-xl-3 py-2 overflow-hidden">
                        <span className="d-inline-block section-5">
                          <Link
                            className="overlay-links text-white"
                            to="genesis-voxel-claim"
                          >
                            Genesis VoxBox
                          </Link>
                        </span>
                      </li>

                      <li className="py-xl-3 py-2 overflow-hidden">
                        <span className="d-inline-block section-5">
                          <Link
                            className="overlay-links text-white"
                            to="lady-voxel-claim"
                          >
                            Lady VoxBox
                          </Link>
                        </span>
                      </li>

                      <li className="py-xl-3 py-2 overflow-hidden">
                        <span className="d-inline-block section-5">
                          <Link
                            className="overlay-links text-white"
                            to="baby-voxel-claim"
                          >
                            Baby VoxBox
                          </Link>
                        </span>
                      </li>

                      {/* END */}

                      <li className="py-xl-3 py-2 overflow-hidden">
                        <span className="d-inline-block section-5">
                          <Link className="overlay-links text-white" to="wac">
                            $WAC
                          </Link>
                        </span>
                      </li>

                      <li className="py-xl-3 py-2 overflow-hidden">
                        <span className="d-inline-block section-5">
                          <Link
                            className="overlay-links text-white"
                            to="weirdification"
                          >
                            Weirdification
                          </Link>
                        </span>
                      </li>
                      <li className="py-xl-3 py-2 overflow-hidden">
                        <span className="d-inline-block section-5">
                          <a
                            href="https://weirdapes.duckdns.org"
                            className="dapp-button px-2"
                          >
                            Open DApp
                          </a>
                        </span>
                      </li>
                      <li className="py-xl-3 py-2 overflow-hidden">
                        <div>
                          <a
                            href="https://weirdape.gitbook.io/weird-apes-club/weird-apes-club/summary"
                            className="nav-links px-2"
                          >
                            <img
                              className="svg-img"
                              src="/assets/img/Girbook icon.png"
                              alt="image"
                            />
                          </a>

                          <a
                            href="https://twitter.com/WeirdApesClub "
                            className="nav-links px-2"
                          >
                            <img
                              className="svg-img"
                              src="/assets/img/header-twitter-icon.svg"
                              alt="image"
                            />
                          </a>
                          <a
                            href="https://medium.com/@WeirdApesClub"
                            className="nav-links px-2"
                          >
                            <img
                              className="svg-img"
                              src="/assets/img/header-medium-icon.svg"
                              alt="image"
                            />
                          </a>
                          <a
                            href="https://discord.gg/BmxRQnz6Nw "
                            className="nav-links px-2"
                          >
                            <img
                              className="svg-img"
                              src="/assets/img/header-discord-icon.svg"
                              alt="image"
                            />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container navbar-container">
            <div
              className="
              nav-quantum
              d-flex
              justify-content-between
              align-items-center
            "
            >
              <a
              // @click="goTo('home')"
              >
                <img className="nav-logo" src="/assets/img/logo.png" />
              </a>
              <div className="nav-items-wrap d-none d-xl-block">
                <ul
                  className="
                  nav-items-list
                  list-unstyled
                  align-items-center
                  d-flex
                  m-auto
                "
                >
                  <li className={`n-item ${pathname !== "/" && "opacity-07"}`}>
                    <Link className={"nav-links "} to="/">
                      Home
                    </Link>
                  </li>
                  <li className="n-item px-2 opacity-07">
                    <HashLink to="/#FAQs" className="nav-links">
                      FAQs
                    </HashLink>
                  </li>

                  {/* INIZIO */}
                  <li
                    className={`n-item px-2 ${
                      pathname !== "/genesis-voxel-claim" && "opacity-07"
                    }`}
                  >
                    <Link className="nav-links" to="genesis-voxel-claim">
                      Genesis VoxBox
                    </Link>
                  </li>

                  <li
                    className={`n-item px-2 ${
                      pathname !== "/lady-voxel-claim" && "opacity-07"
                    }`}
                  >
                    <Link className="nav-links" to="lady-voxel-claim">
                      Lady VoxBox
                    </Link>
                  </li>
                  <li
                    className={`n-item px-2 ${
                      pathname !== "/baby-voxel-claim" && "opacity-07"
                    }`}
                  >
                    <Link className="nav-links" to="baby-voxel-claim">
                      Baby VoxBox
                    </Link>
                  </li>

                  <li
                    className={`n-item px-2 ${
                      pathname !== "/wac" && "opacity-07"
                    }`}
                  >
                    <Link className="nav-links" to="wac">
                      $WAC
                    </Link>
                  </li>

                  <li
                    className={`n-item px-2 ${
                      pathname !== "/weirdification" && "opacity-07"
                    }`}
                  >
                    <Link className="nav-links" to="/weirdification">
                      Weirdification
                    </Link>
                  </li>

                  <li className="n-item px-2 dondola">
                    <a
                      href="https://weirdapes.duckdns.org"
                      className="dapp-button nav-links px-2"
                    >
                      Open DApp
                    </a>
                  </li>
                </ul>
              </div>
              <div className="d-none d-xl-flex align-items-center">
                <a
                  href="https://weirdape.gitbook.io/weird-apes-club/weird-apes-club/summary"
                  className="nav-links px-2"
                >
                  <img
                    className="svg-img"
                    src="/assets/img/Girbook icon.png"
                    alt="image"
                  />
                </a>
                <a
                  href="https://twitter.com/WeirdApesClub "
                  className="nav-links px-2"
                >
                  <img
                    className="svg-img"
                    src="/assets/img/header-twitter-icon.svg"
                    alt="image"
                  />
                </a>
                <a
                  href="https://medium.com/@WeirdApesClub"
                  className="nav-links px-2"
                >
                  <img
                    className="svg-img"
                    src="/assets/img/header-medium-icon.svg"
                    alt="image"
                  />
                </a>
                <a
                  href="https://discord.gg/BmxRQnz6Nw"
                  className="nav-links px-2"
                >
                  <img
                    className="svg-img"
                    src="/assets/img/header-discord-icon.svg"
                    alt="image"
                  />
                </a>
              </div>
              <div
                id="main-menu"
                className={`hamburger-menu d-inline-block d-xl-none ${
                  menuIsOpen && "animate"
                }`}
                onClick={toggleMobileMenu}
              >
                <span className="first" />
                <span className="sec" />
                <span className="third" />
              </div>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
}
