import React from "react";

export default function RoadMap() {
  return (
    <section
      className="
      mt-2
      py-3 py-lg-5
      position-relative
      open_overlay-sections_none
      max-w-1920
      mx-auto
    "
      id="Roadmap"
    >
      <div
        className="text-center mb-4 mb-lg-5 pb-3"
        data-aos="fade-up"
        data-aos-duration={3000}
      >
        <h1 className="main-heading z-index-100">RoadMap</h1>
      </div>
      <div
        className="container position-relative"
        // style={{ marginLeft: 0, marginRight: 0 }}
      >
        <div className="row mb-5 justify-content-center pb-sm-4 z-index-100">
          <div className="col-auto" data-aos="fade-up" data-aos-duration={2000}>
            <div>
              <img
                className=" raodmap-img-hover"
                src="/assets/img/Group_16.png"
              />
            </div>
          </div>
          <div
            className="col-8 col-sm-9 col-lg-7"
            data-aos="fade-up"
            data-aos-duration={2000}
          >
            <div className="ps-lg-5">
              <h1 className="heading font-xl">STAGE I - GENESIS</h1>
              <p className="para">
                2,500 Genesis Weird Apes teamed up to establish a tribe in the
                Cronos Jungle.
                <br />
                It was January 2022, and it was the first time that Apes joined
                the Cronos Chain.
                <br />
                The indigenous peoples immediately loved them:
                <br />
                Sold out of the private sale in less than 10 minutes.
                <br />
                Sold out of the public sale in less than 48 hours.
              </p>
            </div>
          </div>
          <div
            className="col-auto "
            data-aos="fade-up"
            data-aos-duration={2000}
          >
            <div
              style={{
                position: "relative",
                width: "265px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                className=" placeholder_road_map"
                src="/assets/img/RoadMap_Assets/Lore (1) 1.png"
                style={{ width: "240px", position: "absolute", top: -50 }}
              />
            </div>
          </div>
        </div>
        <div className="row mb-5 justify-content-center pb-sm-4 z-index-100 road__map_row">
          <div className="col-auto" data-aos="fade-up" data-aos-duration={2000}>
            <div>
              <img
                className=" raodmap-img-hover"
                src="/assets/img/Group_16.png"
              />
            </div>
          </div>
          <div
            className="col-8 col-sm-9 col-lg-7"
            data-aos="fade-up"
            data-aos-duration={2000}
          >
            <div className="ps-lg-5">
              <h1 className="heading font-xl">
                STAGE II - WEIRDIFICATION PROCESS
              </h1>
              <p className="para mb-0">
                A process created by the best and most specialized Weird
                Scientists, it consists in a contract migration. The
                Weirdification Process unlocks the ability for Genesis Weird
                Apes to yield 8 $WAC per day. $WAC can be used in the Weird Apes
                Club ecosystem for various activities such as name & lore
                customization, breeding, renting, joining Legends of Weirdonite,
                marketplace purchases, governance, and minting future drops!
              </p>
            </div>
          </div>
          <div className="col-auto" data-aos="fade-up" data-aos-duration={2000}>
            <div
              style={{
                position: "relative",
                width: "265px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                className=" placeholder_road_map"
                src="/assets/img/RoadMap_Assets/$WAC 1.png"
                style={{ position: "absolute", top: -100 }}
              />
            </div>
          </div>
        </div>
        <div className="row mb-5 justify-content-center pb-sm-4 z-index-100 road__map_row">
          <div className="col-auto" data-aos="fade-up" data-aos-duration={2000}>
            <div>
              <img
                className=" raodmap-img-hover"
                src="/assets/img/Group_16.png"
              />
            </div>
          </div>
          <div
            className="col-8 col-sm-9 col-lg-7"
            data-aos="fade-up"
            data-aos-duration={2000}
          >
            <div className="ps-lg-5">
              <h1 className="heading font-xl">
                STAGE III - POPULATE THE TRIBE
              </h1>
              <p className="para">
                The launch of Lady Weird Apes initiated the ambitious goal of
                populating the CronosJungle.
                <br />
                Lady Weird Apes collection comes with an ultra limited supply of
                2000 NFTs, each Lady has a dual purpose, she can give birth to
                up to 3 Baby Weird Apes and produce 5 green vials per day. With
                the release of the breeding functionality, the Renting feature
                will find its first use case.
                <br />
                The Renting feature allows users to earn $WAC by lending their
                Weird Apes to help a friend breed a Baby with the rarest and
                most beautiful traits.
              </p>
            </div>
          </div>
          <div className="col-auto" data-aos="fade-up" data-aos-duration={2000}>
            <div
              style={{
                position: "relative",
                width: "265px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                className=" placeholder_road_map"
                src="/assets/img/RoadMap_Assets/Opera_senza_titolo 1.png"
                style={{ position: "absolute", top: -50 }}
              />
            </div>
          </div>
        </div>
        <div className="row mb-5 justify-content-center pb-sm-4 z-index-100 road__map_row">
          <div className="col-auto" data-aos="fade-up" data-aos-duration={2000}>
            <div>
              <img
                className=" raodmap-img-hover"
                src="/assets/img/Group_16.png"
              />
            </div>
          </div>
          <div
            className="col-8 col-sm-9 col-lg-7"
            data-aos="fade-up"
            data-aos-duration={2000}
          >
            <div className="ps-lg-5">
              <h1 className="heading font-xl">STAGE IV - VOXEL APES </h1>
              <p className="para">
                Weird Apes will leave their original 2D home and begin
                traversing the 3D Metaverse!
                <br />
                Owners of a 2D Ape can burn $WAC to claim an additional new
                voxel-based Weird Ape NFT which can be used to join Legends of
                Weirdonite and to make the right impression in the Metaverse. 3D
                Voxel Apes will have the exact same traits as their 2D
                counterparts!
              </p>
            </div>
          </div>
          <div className="col-auto" data-aos="fade-up" data-aos-duration={2000}>
            <div
              style={{
                position: "relative",
                width: "265px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                className=" placeholder_road_map"
                src="/assets/img/RoadMap_Assets/snap2022-03-22-22-42-13 1.png"
                style={{ position: "absolute", top: -50 }}
              />
            </div>
          </div>
        </div>

        <div className="row mb-5 justify-content-center pb-sm-4 z-index-100 road__map_row">
          <div className="col-auto" data-aos="fade-up" data-aos-duration={2000}>
            <div>
              <img
                className=" raodmap-img-hover"
                src="/assets/img/Group_16.png"
              />
            </div>
          </div>
          <div
            className="col-8 col-sm-9 col-lg-7"
            data-aos="fade-up"
            data-aos-duration={2000}
          >
            <div className="ps-lg-5">
              <h1 className="heading font-xl">
                STAGE V - LEGENDS OF WEIRDONITE{" "}
              </h1>
              <p className="para">
                After months of research the Weird Explorers have found a
                perfect place to build their home village. To power the
                ecosystem Weird Apes need an energy source called Weirdonite, a
                very rare resource to find, but even worse, it can only be found
                in the Void Dimension. Voxel Apes will be sent on expeditions in
                search of Weirdonite, but beware and prepare your equipment,
                many pitfalls lurk in the Void. Legends of Weirdonite brings
                utility and perks for $WAC as well as all NFTs in the Club.
              </p>
            </div>
          </div>
          <div className="col-auto" data-aos="fade-up" data-aos-duration={2000}>
            <div
              style={{
                position: "relative",
                width: "265px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                className=" placeholder_road_map"
                src="/assets/img/RoadMap_Assets/Weirdonite(no bg) 1.png"
                style={{ position: "absolute", top: -50 }}
              />
            </div>
          </div>
        </div>

        <div className="row mb-5 justify-content-center pb-sm-4 z-index-100 road__map_row">
          <div className="col-auto" data-aos="fade-up" data-aos-duration={2000}>
            <div>
              <img
                className=" raodmap-img-hover"
                src="/assets/img/Group_16.png"
              />
            </div>
          </div>
          <div
            className="col-8 col-sm-9 col-lg-7"
            data-aos="fade-up"
            data-aos-duration={2000}
          >
            <div className="ps-lg-5">
              <h1 className="heading font-xl">STAGE VI - WEIRD SHOP </h1>
              <p className="para">
                It is necessary to connect a multitude of dispersed sources in
                order to progress, the Weird Merchants have come together to
                open the first marketplace in the Cronosjungle, the Weird Shop!
                The Weird Shop unlocks the free trade of all Weird Apes Club
                NFTs in $WAC. Equip your Voxel Apes with the most advanced
                weapons to collect as much loot as possible in Legends of
                Weirdonite, or dress them up with the rarest wearables to look
                awesome in the CronosJungle.
              </p>
            </div>
          </div>
          <div className="col-auto" data-aos="fade-up" data-aos-duration={2000}>
            <div
              style={{
                position: "relative",
                width: "265px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                className=" placeholder_road_map"
                src="/assets/img/RoadMap_Assets/Shop 1.png"
                style={{ position: "absolute", top: -20 }}
              />
            </div>
          </div>
        </div>

        <div className="row mb-5 justify-content-center pb-sm-4 z-index-100 road__map_row">
          <div className="col-auto" data-aos="fade-up" data-aos-duration={2000}>
            <div>
              <img
                className=" raodmap-img-hover"
                src="/assets/img/Group_16.png"
              />
            </div>
          </div>
          <div
            className="col-8 col-sm-9 col-lg-7"
            data-aos="fade-up"
            data-aos-duration={2000}
          >
            <div className="ps-lg-5">
              <h1 className="heading font-xl">STAGE VII - CRONOS JUNGLE </h1>
              <p className="para">
                Thanks to all Weirdonite collected, we will finally build our
                home village, the CronosJungle.
                <br />
                What is in the CronosJungle, few are allowed to know. Great
                pitfalls and opportunities lurk within it and only time will
                light the way.
              </p>
            </div>
          </div>
          <div className="col-auto" data-aos="fade-up" data-aos-duration={2000}>
            <div
              style={{
                position: "relative",
                width: "265px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                className=" placeholder_road_map"
                src="/assets/img/RoadMap_Assets/Gif jungle 1.png"
                style={{ position: "absolute", top: -50 }}
              />
            </div>
          </div>
        </div>

        <div className="row mb-5 justify-content-center pb-sm-4 z-index-100 road__map_row">
          <div className="col-auto" data-aos="fade-up" data-aos-duration={2000}>
            <div>
              <img
                className=" raodmap-img-hover"
                src="/assets/img/Group_16.png"
              />
            </div>
          </div>
          <div
            className="col-8 col-sm-9 col-lg-7"
            data-aos="fade-up"
            data-aos-duration={2000}
          >
            <div className="ps-lg-5">
              <h1 className="heading font-xl">
                STAGE VIII - WEIRDNESS TAKES OVER{" "}
              </h1>
              <p className="para">
                We can&apos;t even imagine the weird things that will happen
                inside the CronosJungle.
                <br />
                We&apos;ll test other projects and see if they&apos;re weird
                enough to join the party!
              </p>
            </div>
          </div>
          <div className="col-auto" data-aos="fade-up" data-aos-duration={2000}>
            <div
              style={{
                position: "relative",

                width: "265px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                className=" placeholder_road_map"
                src="/assets/img/RoadMap_Assets/question-mark-circle 1.png"
                style={{ width: "150px", position: "absolute", top: -50 }}
              />
            </div>
          </div>
        </div>
        <div className="position-absolute roadmap-line">
          <img src="/assets/img/Group 2.png" />
        </div>
      </div>
      {/* <div className='position-absolute image-12 d-none d-md-block'>
        <img className='' src='/assets/img/image_12.png ' />
      </div>
      <div className='position-absolute image-13 d-none d-md-block'>
        <img className='' src='/assets/img/image_13.png ' />
      </div>
      <div className='position-absolute image-14 d-none d-md-block'>
        <img className='' src='/assets/img/image_14.png ' />
      </div>
      <div className='position-absolute image-15 d-none d-md-block'>
        <img className='' src='/assets/img/image_15.png ' />
      </div> */}
    </section>
  );
}
