import React from "react";

export default function Faq() {
  return (
    <footer
      className="mt-2 mt-md-2 pt-md-2 open_overlay-sections_none"
      id="FAQs"
    >
      <section
        itemID="faq"
        className="faq-section-bg position-relative py-sm-5"
        id="faq"
      >
        <div className="container mb-5 py-3 z-index-100">
          <div className="row justify-content-center">
            <div data-aos="fade-up" data-aos-duration={3000}>
              <h1 className="main-heading text-center mb-4 mb-md-5">FAQs</h1>
            </div>
            <div className="col-lg-9">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div
                  className="accordion-item mb-3"
                  data-aos="fade-right"
                  data-aos-duration={3000}
                >
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      <p className="sub-heading text-white mb-0">
                        How can I mint Weird Apes NFT ?
                      </p>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p className="para ff-inter mb-0 pb-4">
                        All our collections are sold out, so no mint is active.
                        <br />
                        You can either buy a WAC NFT on a marketplace, or
                        generate an Incubator and thus a Baby Weird Ape on our
                        DApp via $WAC and/or Genesis and Lady. Remember to set
                        Cronos Beta Mainnet as network when connecting to our
                        DApp.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item mb-3"
                  data-aos="fade-left"
                  data-aos-duration={3000}
                >
                  <h2 className="accordion-header" id="flush-headingtwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapsetwo"
                      aria-expanded="false"
                      aria-controls="flush-collapsetwo"
                    >
                      <p className="sub-heading text-white mb-0">
                        How to set Cronos Network on your wallet?
                      </p>
                    </button>
                  </h2>
                  <div
                    id="flush-collapsetwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingtwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p className="para ff-inter mb-0 pb-4">
                        Cronos mainnet Beta Details:
                        <br />
                        -Network: Cronos
                        <br />
                        -New RPC URL:
                        <a
                          className="text-decoration-none para text-white"
                          href="https://cronos.crypto.org/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://cronos.crypto.org/
                        </a>
                        <br />
                        -Chain ID: 25
                        <br />
                        -Symbol: CRO
                        <br />
                        -Block explorer URL:
                        <a
                          className="text-decoration-none para text-white"
                          href="https://cronos.crypto.org/explorer/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://cronos.crypto.org/explorer/
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item mb-3"
                  data-aos="fade-right"
                  data-aos-duration={3000}
                >
                  <h2 className="accordion-header" id="flush-headingthree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapsethree"
                      aria-expanded="false"
                      aria-controls="flush-collapsethree"
                    >
                      <p className="sub-heading mb-0">
                        How many Genesis Weird Apes are there?
                      </p>
                    </button>
                  </h2>
                  <div
                    id="flush-collapsethree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingthree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p className="para ff-inter mb-0 pb-4">
                        There are 2500 Genesis Weird Apes in total.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item mb-3"
                  data-aos="fade-left"
                  data-aos-duration={3000}
                >
                  <h2 className="accordion-header" id="flush-headingfour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapsefour"
                      aria-expanded="false"
                      aria-controls="flush-collapsefour"
                    >
                      <p className="sub-heading mb-0">
                        How many Lady Weird Apes are there?
                      </p>
                    </button>
                  </h2>
                  <div
                    id="flush-collapsefour"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingfour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p className="para ff-inter mb-0 pb-4">
                        There are 2000 Lady Weird Apes in total.
                      </p>
                    </div>
                  </div>
                </div>
                {/*
                <div
                  className='accordion-item mb-3'
                  data-aos='fade-right'
                  data-aos-duration={3000}>
                  <h2 className='accordion-header' id='flush-headingfive'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#flush-collapsefive'
                      aria-expanded='false'
                      aria-controls='flush-collapsefive'>
                      <p className='sub-heading mb-0'>
                        How many Weird Lady Apes are there?
                      </p>
                    </button>
                  </h2>
                  <div
                    id='flush-collapsefive'
                    className='accordion-collapse collapse'
                    aria-labelledby='flush-headingfive'
                    data-bs-parent='#accordionFlushExample'>
                    <div className='accordion-body'>
                      <p className='para ff-inter mb-0 pb-4'>2000.</p>
                    </div>
                  </div>
                </div>
                */}
                <div
                  className="accordion-item mb-3"
                  data-aos="fade-left"
                  data-aos-duration={3000}
                >
                  <h2 className="accordion-header" id="flush-headingsix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapsesix"
                      aria-expanded="false"
                      aria-controls="flush-collapsesix"
                    >
                      <p className="sub-heading mb-0">
                        What about breeding requirements?
                      </p>
                    </button>
                  </h2>
                  <div
                    id="flush-collapsesix"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingsix"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p className="para ff-inter mb-0 pb-4">
                        Breeding requires the ownership of both Genesis Weird
                        Ape and a Weird Lady Ape, and $WAC token. This token
                        will be slowly distributed during time to all Genesis
                        Weird Apes holders.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item mb-3"
                  data-aos="fade-right"
                  data-aos-duration={3000}
                >
                  <h2 className="accordion-header" id="flush-headingseven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseseven"
                      aria-expanded="false"
                      aria-controls="flush-collapseseven"
                    >
                      <p className="sub-heading mb-0">
                        How many Weird Baby Apes can a Weird Lady Ape give
                        birth?
                      </p>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseseven"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingseven"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p className="para ff-inter mb-0 pb-4">
                        A Weird Lady Ape can give birth up to 3 times, instead
                        Genesis Weird Apes have no breeding limits.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item mb-3"
                  data-aos="fade-left"
                  data-aos-duration={3000}
                >
                  <h2 className="accordion-header" id="flush-headingEight">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseEight"
                      aria-expanded="false"
                      aria-controls="flush-collapseEight"
                    >
                      <p className="sub-heading mb-0">
                        How many Weird Baby Apes there will be?
                      </p>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseEight"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingEight"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p className="para ff-inter mb-0 pb-4">
                        There will be up to a maximum of 6000 Babies.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item mb-3"
                  data-aos="fade-left"
                  data-aos-duration={3000}
                >
                  <h2 className="accordion-header" id="flush-headingNine">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseNine"
                      aria-expanded="false"
                      aria-controls="flush-collapseNine"
                    >
                      <p className="sub-heading mb-0">
                        How $WAC works? Which utilities the token has?
                      </p>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseNine"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingNine"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p className="para ff-inter mb-0 pb-4">
                        $WAC is both a utility token and a governance token! Go
                        to the $WAC section or read about it in our gitbook to
                        learn more!
                        {/*
                        It fuels Weird Apes Club ecosystem, allowing breeding, renting,
                        change name &amp; lore, voting and it has in-game
                        utilities. Take a look on $WAC page, on our website to
                        know more about it!
                        */}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item mb-3"
                  data-aos="fade-left"
                  data-aos-duration={3000}
                >
                  <h2 className="accordion-header" id="flush-headingTen">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTen"
                      aria-expanded="false"
                      aria-controls="flush-collapseTen"
                    >
                      <p className="sub-heading mb-0">
                        Where can I find more information about the project?
                      </p>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTen"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTen"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p className="para ff-inter mb-0 pb-4">
                        Check our gitbook or our medium!
                        <br />
                        <a
                          className="para text-white"
                          href="https://medium.com/@WeirdApesClub"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          gitbook:
                          https://weirdape.gitbook.io/weird-apes-club/weird-apes-club/summary
                          <br />
                          medium: https://medium.com/@WeirdApesClub
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}
