import React from 'react';

export default function Genetics() {
    return (
        <section
            className='position-relative open_overlay-sections_none'
            id='Genetics'>
            <img
                src='/assets/img/Ellipse_9.png'
                className='position-absolute geneticsLeft_img'
            />
            <div className='container pt-sm-5 pt-4 z-index-100'>
                <div
                    className='text-center mb-3 mb-sm-4'
                    data-aos='fade-up'
                    data-aos-duration={3000}>
                    <h2 className='main-heading'>Genetics</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-4' data-aos='zoom-in' data-aos-duration={3000}>
                        <div className='content_box'>
                            <h2 className='sub-heading my-3'>GENESIS WEIRD APES</h2>
                            <p className='para'>
                2500 Genesis Weird Apes with 124 traits. Native inhabitants of
                the CronosJungle tribe, no one knows who they are and what they
                want, but there is a certainty there, they are the weirdest NFTs
                on Cronos Chain..
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-4' data-aos='zoom-in' data-aos-duration={3000}>
                        <div className='content_box'>
                            <h2 className='sub-heading my-3'>LADY WEIRD APES</h2>
                            <p className='para'>
                2000 beautiful Lady Weird Apes with 124 traits, their charm has
                bewitched the native Weird Apes peoples of the CronosJungle.
                They are very rare due to their limited quantity, currently we
                only know they can reproduce up to 3 times but who knows what
                they have in reserve for the future.
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-4' data-aos='zoom-in' data-aos-duration={3000}>
                        <div className='content_box'>
                            <h2 className='sub-heading my-3'>BABY WEIRD APES</h2>
                            <p className='para'>
                6000 little weirdos, here are the Baby Weird Apes. They are very
                cute and pretty, but some of them are really weird. Their
                special feature? More than 300 traits, some inherited, some new.
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className='row z-index-100'
                    data-aos='fade-up'
                    data-aos-duration={3000}>
                    <div className='col-lg-12 justify-content-center align-items-center mt-5 mb-5'>
                        <div className='text-center'>
                            <img
                                src='/assets/img/Breed_website_1.png'
                                className='w-100 w-md-75 d-block d-lg-none'
                                alt='Breed image'
                            />
                            <img
                                src='/assets/img/Dreading_graphics_desktop_view.png'
                                className='w-100 w-md-75 d-none d-lg-block'
                                alt='Breed image'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
