import React from "react";
import { useRoutes } from "react-router-dom";
import Layout from "./layout";

import Home from "./pages/Home";
import GenesisVoxelClaim from "./pages/GenesisVoxelClaim";
import LadyVoxelClaim from "./pages/LadyVoxelClaim";
import BabyVoxelClaim from "./pages/BabyVoxelClaim";

import Wac from "./pages/Wac";
import Weirdification from "./pages/Weirdification";

export default function Router() {
  return useRoutes([
    { path: "/", element: <Home /> },
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "genesis-voxel-claim",
          element: <GenesisVoxelClaim />,
        },
        {
          path: "lady-voxel-claim",
          element: <LadyVoxelClaim />,
        },
        {
          path: "baby-voxel-claim",
          element: <BabyVoxelClaim />,
        },
        {
          path: "wac",
          element: <Wac />,
        },
        {
          path: "weirdification",
          element: <Weirdification />,
        },
      ],
    },
  ]);
}
