import React, { useEffect, useState /* , useRef */ } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import AButton from "../components/AButton";

//const truncate = (input, len) =>
//  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 400px;
  @media (min-width: 767px) {
    width: 500px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  background-color: var(--accent);
  border-radius: 0%;
  width: 300px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 500px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function GenesisVoxelClaim() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

  const VxDistributorContract = "0x5eE5dEC0d7D2EFe4a4E5D775B8BBdbb27121402A";
  // const data = useSelector((state) => state.data);
  // const { tokenContract,} = blockchain;

  const [totalSupply, setTotalSupply] = useState(0);
  const [wacBalance, setWacBalance] = useState(0);
  const [claimingNft, setClaimingNft] = useState(false);
  const [controllato, setControllato] = useState(false);
  const [claimableNFTs, setClaimableNFTs] = useState(0);
  const [authorized, setAuthorized] = useState(false);

  const [feedback, setFeedback] = useState("");

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    VOXEL_APES_CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      RPC: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    PAUSED: false,
  });

  const checkIsApproved = () => {
    blockchain.tokenContract.methods
      .allowance(blockchain.address, VxDistributorContract)
      .call({ from: blockchain.address })
      .then((allowance) => {
        if (allowance == 0) {
          setAuthorized(false);
        } else {
          setAuthorized(true);
        }
      });
  };

  const updateWacBalance = () => {
    blockchain.tokenContract.methods
      .balanceOf(blockchain.address)
      .call({ from: blockchain.address })
      .then((balance) => {
        setWacBalance(balance);
      });
  };

  const aggiornamentoTotalSupply = () => {
    blockchain.VoxelApesContract.methods
      .totalSupplies(false, 0)
      .call({ from: blockchain.address })
      .then((tot_supply) => {
        setTotalSupply(tot_supply);
      });
  };

  const aggiornaConteggioDeiClaimable = () => {
    blockchain.VoxelApesContract.methods
      .MaxClaimable(blockchain.address, 0)
      .call({ from: blockchain.address })
      .then((num_claimable) => {
        setClaimableNFTs(num_claimable.length);
        if (num_claimable.length > 0) {
          setFeedback(
            "Click to claim all the Genesis VoxBoxes to which you are entitled!\n"
          );
        }
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (blockchain.address) {
        dispatch(fetchData(blockchain.address));
      }
    }, CONFIG.REFRESH_TIME * 1000);
    return () => clearInterval(interval);
  }, [blockchain.address]);

  const authorize = () => {
    if (!authorized) {
      const approveAmount =
        "115792089237316195423570985008687907853269984665640564039457584007913129639935"; // (2**256 - 1 )
      blockchain.tokenContract.methods
        .approve(VxDistributorContract, approveAmount)
        .send({
          gasLimit: 285000,
          to: blockchain.tokenContract,
          from: blockchain.address,
          //value: 0,
        });
      setAuthorized(true);
    }
  };

  const claimNFTs = () => {
    const gasLimit = CONFIG.GAS_LIMIT;
    const totalGasLimit = String(gasLimit * claimableNFTs);

    setFeedback("Claiming your Voxels...");
    setClaimingNft(true);

    if (claimableNFTs > 0) {
      blockchain.VoxelApesContract.methods
        .MaxClaim(0)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.address,
        })
        .once("error", (err) => {
          console.error(err);
          setFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.error(receipt);
          setFeedback("WOW, you have just unlocked your Voxel Boxes!");
          setClaimingNft(false);
          dispatch(fetchData(blockchain.address));
          aggiornaConteggioDeiClaimable();
        })
        .catch((error) => {
          console.error(error);
          setFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        });
    } else {
      setFeedback("You can not claim any Voxel Box.");
      setClaimingNft(false);
    }
  };

  const getData = () => {
    if (blockchain.address !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.address));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/configVoxels.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.address]);

  const _paused = false;

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{
          padding: 24,
          backgroundColor: "var(--primary)",
          color: "white",
        }}
        image={null}
        className="z-index-100"
        id="lady__min__container"
      >
        <StyledLogo alt={"logo"} src={"/config/images/TextClaimImage.png"} />
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/Voxel2.png"} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              border: "#ff961d",
              boxShadow: "",
            }}
          >
            {!_paused && (
              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--primary-text)",
                }}
              >
                <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                  {/*
                                    Contract Address:<br/>
                                    {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                                    */}
                </StyledLink>
              </s.TextDescription>
            )}
            <s.SpacerSmall />
            {_paused ? (
              <s.TextDescription
                style={{ textAlign: "center", color: "var(--accent-text)" }}
              >
                VoxBoxes are your ticket for the upcoming, evolved 3d version of
                VoxBoxes, Known as The Explorables!
                <br />
                Explorables will be your Social Avatar for the Metaverse and
                will grant you access to the P2E Legends of Weirdonite.
                <br />
                <br />
                Each Genesis can apply for the corresponding VoxBox only once!
                <br />
                Are required 960 $WAC for each VoxBox, which will be burned and
                used for yield farming campaigns!
              </s.TextDescription>
            ) : (
              <>
                {blockchain.address === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      VoxBoxes are your ticket for the upcoming, evolved 3d
                      version of VoxBoxes, Known as The Explorables!
                      <br />
                      Explorables will be your Social Avatar for the Metaverse
                      and will grant you access to the P2E Legends of
                      Weirdonite.
                      <br />
                      <br />
                      Connect to the {CONFIG.NETWORK.NAME} network to request
                      your VoxBoxes!
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <AButton
                      text="CONNECT"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                        aggiornamentoTotalSupply();
                        updateWacBalance();
                        aggiornaConteggioDeiClaimable();
                      }}
                    />
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    {controllato ? (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {feedback}
                        </s.TextDescription>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          Supply:
                          <br />
                          {totalSupply}/2500
                          <br />
                          <br />
                          {claimableNFTs > 0 ? (
                            <>
                              You can claim {claimableNFTs} Voxels!
                              <br />
                              $WAC Required:
                              <br />
                              {claimableNFTs * 960}
                              <br />
                              $WAC Balance:
                              <br />
                              {Math.round(wacBalance / 10 ** 18, 2)}
                            </>
                          ) : (
                            <>You can&apos;t claim any Genesis VoxBox.</>
                          )}
                        </s.TextDescription>
                        <s.SpacerMedium />
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          <s.SpacerMedium />
                          <s.SpacerMedium />
                        </s.Container>
                        <s.SpacerSmall />
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          {authorized ? (
                            <>
                              <AButton
                                text={claimingNft ? "LOADING" : "CLAIM"}
                                disabled={claimingNft || !claimableNFTs ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFTs();
                                  getData();
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <AButton
                                text={authorized ? "LOADING" : "APPROVE"}
                                //disabled={claimingNft || !claimableNFTs ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  authorize();
                                  //getData();
                                }}
                              />
                            </>
                          )}
                        </s.Container>
                      </>
                    ) : (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          Claim your VoxBoxes for each Genesis Weird Ape you
                          hold in your wallet!
                          <br />
                          <br />
                          To request a single VoxBox, connect to the DApp and
                          request it via the Claim button in the specific
                          Genesis tab in My Wapes section.
                          <br />
                          <br />
                          960 $WAC are required for each VoxBox, which will be
                          burned and used for yield farming campaigns.
                          <br />
                          Each Genesis can apply for the corresponding VoxBox
                          only once!
                        </s.TextDescription>
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          <AButton
                            text={"START!"}
                            onClick={(e) => {
                              e.preventDefault();
                              setControllato(true);
                              aggiornaConteggioDeiClaimable();
                              updateWacBalance();
                              aggiornamentoTotalSupply();
                              checkIsApproved();
                            }}
                          />
                        </s.Container>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/Voxel1.png"} />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          ></s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          ></s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default GenesisVoxelClaim;
