import { actionTypes } from "./actionTypes";

const initialState = {
  // App
  loading: {
    active: false,
    msg: "",
  },

  // Web3
  provider: null,
  web3: null,
  chainId: null,
  connected: null,
  address: null,

  // Contracts
  tokenContract: null,
  oldApesContract: null,
  genesisApesContract: null,
  VoxelApesContract: null,
  proxyContract: null,

  // $WAC
  tokenBalance: null,
  tokenPendingBalance: null,
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.setLoading:
      return {
        ...state,
        loading: {
          active: !action?.payload ? false : Boolean(action?.payload),
          msg: action?.msg,
        },
      };
    case actionTypes.registerWeb3:
      return {
        ...state,
        provider: action?.payload?.provider,
        web3: action?.payload?.web3,
        address: action?.payload?.address,
        connected: action?.payload?.connected,
      };

    case actionTypes.updateChainId:
      return {
        ...state,
        chainId: action?.payload?.chainId,
      };

    case actionTypes.updateTokenContract:
      return {
        ...state,
        tokenContract: action?.payload?.tokenContract,
      };
    case actionTypes.updateOldApesContract:
      return {
        ...state,
        oldApesContract: action?.payload?.oldApesContract,
      };
    case actionTypes.updateGenesisApesContract:
      return {
        ...state,
        genesisApesContract: action?.payload?.genesisApesContract,
      };
    case actionTypes.updateVoxelApesContract:
      return {
        ...state,
        VoxelApesContract: action?.payload?.VoxelApesContract,
      };
    case actionTypes.updateProxyContract:
      return {
        ...state,
        proxyContract: action?.payload?.proxyContract,
      };
    // Balance
    case actionTypes.updateTokenBalance:
      return {
        ...state,
        tokenBalance: action?.payload,
      };
    case actionTypes.updateTokenPendingBalance:
      return {
        ...state,
        tokenPendingBalance: action?.payload,
      };
    default:
      return state;
  }
};

export default blockchainReducer;
