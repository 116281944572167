import React from "react";
export default function HeroSection() {
  return (
    <>
      <section
        className="
        z-index-100
        d-flex
        flex-column
        open_overlay-sections_none
        justify-content-center
        align-items-center
        flex-grow-1
        my-5
        py-5 py-sm-0
        my-sm-0
      "
        id="Home"
      >
        <div className="container">
          <div className="row justify-content-center justify-content-lg-between">
            <div
              className="home-tile-and-actions"
              data-aos="fade-right"
              data-aos-duration={3000}
            >
              <div>
                <h1 className="hero-heading mb-4 mb-sm-5">
                  The Weird <br />
                  Apes Club
                </h1>
              </div>
              <div className="d-sm-flex align-items-center">
                <div className="me-sm-4 mb-5 position-relative main-box-btn">
                  <a
                    href="https://app.ebisusbay.com/brands/weird-apes-club"
                    className="connect-wallet-btn px-4 py-3 text-white z-index-100"
                  >
                    BUY A WAC NFT
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
