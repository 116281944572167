import React from "react";

export default function Footer() {
  return (
    <section className="footer pt-5 position-relative">
      <div className="container z-index-100">
        <div className="text-center">
          <div className="mb-5" data-aos="fade-up" data-aos-duration={3000}>
            <a>
              <img className="nav-logo" src="/assets/img/logo.png" />
            </a>
          </div>
          <div className="mb-4" data-aos="zoom-in" data-aos-duration={3000}>
            <a href="https://discord.gg/BmxRQnz6Nw" className="nav-links px-2">
              <img
                className="svg-img"
                src="/assets/img/discord-icon.svg"
                alt="image"
              />
            </a>
            <a
              href=" https://medium.com/@WeirdApesClub"
              className="nav-links px-2"
            >
              <img
                className="svg-img px-2"
                src="/assets/img/medium-icon.svg"
                alt="image"
              />
            </a>
            <a href="https://twitter.com/WeirdApesClub" className="nav-links">
              <img
                className="svg-img"
                src="/assets/img/twitter-icon.png"
                alt="image"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="w-100 z-index-100">
        <img className="w-100" src="/assets/img/Line_3.png" />
      </div>
      <div className="text-center py-3 z-index-100">
        <p className="opacity-07 white para font-xs mb-0">© Weird Apes Club</p>
      </div>
      <div className="position-absolute start-0 bottom-0 z-index-2">
        <img className="w-100" src="/assets/img/Ellipse_12.png" />
      </div>
    </section>
  );
}
