import { batch } from "react-redux";
// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
// log
import { fetchData } from "../data/dataActions";

//personalized: our abis + connection extensions
import Web3Modal, { providers } from "web3modal";

import WalletConnectProvider from "@walletconnect/web3-provider";
import { DeFiWeb3Connector } from "deficonnect";

import oldApesABI from "../../assets/old_apes_abi";
import newApesABI from "../../assets/new_apes_abi";
import genesis3dABI from "../../assets/voxelsABI";
import tokenABI from "../../assets/token_abi";
import proxyContractABI from "../../assets/proxyContractABI";
import * as OLD_CONFIG from "../../assets/config";

import { actionTypes } from "./actionTypes";

function prepareWeb3Modal(config) {
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: "4e996ad0d62449cab486de7242d26ef6",
        rpc: {
          [config.NETWORK.ID]: config.NETWORK.RPC,
        },
      },
    },

    injected: {
      display: {
        logo: "https://github.com/MetaMask/brand-resources/raw/master/SVG/metamask-fox.svg",
        name: "MetaMask",
        description: "Connect with MetaMask in your browser",
      },
      package: null,
    },

    "custom-defiwallet": {
      display: {
        logo: "https://crypto.com/static/06ab78bca1a07a4fd7f9146c8e600d0f/415a1/icon.png",
        name: "Crypto.com DeFi Wallet",
        description: "Connect with DeFi Wallet browser extension or app",
      },
      options: {},
      package: WalletConnectProvider,
      connector: async () => {
        const connector = new DeFiWeb3Connector({
          supportedChainIds: [config.NETWORK.ID],
          rpc: {
            [config.NETWORK.ID]: config.NETWORK.RPC,
          },
        });

        await connector.activate();
        return await connector.getProvider();
      },
    },
  };

  if (!window.ethereum) {
    providerOptions["custom-metamask"] = {
      display: {
        logo: providers.METAMASK.logo,
        name: "Install MetaMask",
        description: "Connect using browser wallet",
      },
      package: {},
      connector: async () => {
        window.open("https://metamask.io");
        throw new Error("MetaMask not installed");
      },
    };
  }

  const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions,
  });
  // web3Modal.clearCachedProvider();
  return web3Modal;
}

export const getWeb3Modal = async () => {
  const configResponse = await fetch("/config/config.json", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  const CONFIG = await configResponse.json();
  return prepareWeb3Modal(CONFIG);
};

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

// const connectSuccess = (payload) => {
//     return {
//         type: 'CONNECTION_SUCCESS',
//         payload: payload,
//     };
// };

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();

    // UNCOMMENT TO USE METAMASK WITHOUT ASKING (IF AVAILABLE)
    let provider;
    // = window.ethereum;
    // if (!provider) {
    try {
      // localStorage.clear();
      // const web3Modal = prepareWeb3Modal(CONFIG);
      const web3Modal = await getWeb3Modal();
      provider = await web3Modal.connect();
    } catch {
      dispatch(connectFailed("Connection refused by the user"));
    }
    // }
    // }

    if (provider) {
      // provider = new providers.Web3Provider(provider);
      Web3EthContract.setProvider(provider);
      const web3 = new Web3(provider);
      try {
        // ACCOUNT
        let account;
        if (provider.accounts && provider.accounts[0]) {
          account = provider.accounts[0];
        } else {
          const accounts = await provider.request({
            method: "eth_requestAccounts",
          });
          account = accounts[0];
        }

        // NETWORK
        let networkId;
        if (provider.chainId) {
          networkId = provider.chainId;
        } else {
          networkId = await provider.request({ method: "net_version" });
        }

        if (networkId == CONFIG.NETWORK.ID) {
          const tokenContract = new Web3EthContract(
            tokenABI,
            OLD_CONFIG.TOKEN_CONTRACT_ADDRESS
          );
          const oldApesContract = new Web3EthContract(
            oldApesABI,
            OLD_CONFIG.ORIG_CONTRACT_ADDRESS
          );
          const newApesContract = new Web3EthContract(
            newApesABI,
            OLD_CONFIG.GENESIS_CONTRACT_ADDRESS
          );
          const VoxelApesContract = new Web3EthContract(
            genesis3dABI,
            OLD_CONFIG.GENESIS_3D_CONTRACT_ADDRESS
          );
          const proxyApesContract = new Web3EthContract(
            proxyContractABI,
            OLD_CONFIG.PROXY_CONTRACT_ADDRESS
          );
          batch(() => {
            dispatch({
              type: actionTypes.registerWeb3,
              payload: {
                provider: provider,
                web3: web3,
                address: account,
                connected: true,
              },
            });
            dispatch({
              type: actionTypes.updateTokenContract,
              payload: { tokenContract },
            });
            dispatch({
              type: actionTypes.updateOldApesContract,
              payload: { oldApesContract },
            });
            dispatch({
              type: actionTypes.updateGenesisApesContract,
              payload: { genesisApesContract: newApesContract },
            });
            dispatch({
              type: actionTypes.updateVoxelApesContract,
              payload: { VoxelApesContract: VoxelApesContract },
            });
            dispatch({
              type: actionTypes.updateProxyContract,
              payload: { proxyContract: proxyApesContract },
            });
            dispatch({
              type: actionTypes.updateChainId,
              payload: { chainId: networkId },
            });
          });

          dispatch(fetchUserBalances());

          provider.on("connect", (/* connectionInfo */) => {
            //
          });
          provider.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0].toLowerCase()));
          });
          provider.on("chainChanged", () => {
            window.location.reload();
          });
          provider.on("disconnect", () => {
            window.location.reload();
          });
          provider.on("message", (message) => {
            console.log("Message from provider: ", message);
          });
        } else {
          dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
        }
      } catch (err) {
        dispatch(connectFailed("Something went wrong."));
      }
    } else {
      dispatch(connectFailed("Connection to provider not established"));
    }
  };
};

export const setLoading = (payload) => {
  return async (dispatch /* , getState */) => {
    dispatch({
      type: actionTypes.setLoading,
      payload: payload,
    });
  };
};

export const fetchUserBalances = () => {
  return async (dispatch, getState) => {
    const { blockchain } = getState();

    try {
      const response = await blockchain.proxyContract.methods
        .getBalancesOfUser(blockchain.address)
        .call({ from: blockchain.address });

      batch(() => {
        dispatch({
          type: actionTypes.updateTokenBalance,
          payload: Math.floor(+response[0] / 10 ** 18),
        });
        dispatch({
          type: actionTypes.updateTokenPendingBalance,
          payload: Math.floor(+response[1] / 10 ** 18),
        });
      });
    } catch (e) {
      // console.error(e);
    }
  };
};
