import React from "react";
import { useSelector } from "react-redux";

import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import LoadingOverlay from "../components/LoadingOverlay";

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const blockchain = useSelector((state) => state.blockchain);
  const isLoading = blockchain.loading.active;

  return (
    <>
      <main className="main-top-section ">
        <Navbar />
      </main>
      <Outlet />
      <Footer />
      <LoadingOverlay active={isLoading} />
    </>
  );
}
