import React from 'react';

export default function About() {
    return (
        <section
            className='about_img position-relative open_overlay-sections_none'
            id='About'>
            <img
                src='/assets/img/Ellipse_8.png'
                className='about_rightImg position-absolute'
            />
            <div className='container mt-5 mb-4 z-index-100'>
                <div className='row justify-content-center justify-content-xxl-end'>
                    <div
                        className='col-md-10 col-xxl-6 d-block d-xxl-none mb-4 padding-sm-unset'
                        data-aos='fade-right'
                        data-aos-duration={3000}>
                        <div className='mb-2'>
                            <img className='w-100' src='/assets/img/ape_6.gif' />
                        </div>
                    </div>
                    <div
                        className='col-12 col-xxl-6 justify-content-center align-items-center'
                        data-aos='fade-left'
                        data-aos-duration={3000}>
                        <div>
                            <h2 className='main-heading text-center text-xxl-start mb-3 mb-md-4'>
                ABOUT
                            </h2>
                            <div>
                                <h3 className='sub-heading'>
                  THE FIRST APES ECOSYSTEM BUILT ON CRONOS CHAIN
                                </h3>
                                <p className='para'>
                  Weird apes club is a breedable collection algorithmically
                  generated. We’re building a sustainable ecosystem for
                  enthusiasts on Crypto.com’s blockchain, including Breeding
                  feature, DeFi, Governance and more.
                                </p>
                                <h3 className='sub-heading'>
                  ALL APES ARE EQUALS, BUT SOME APES ARE MORE EQUAL THAN OTHERS
                                </h3>
                                <p className='para'>
                  Each Genesis Weird Ape is generated from 124 possible traits.
                  Each trait has its own % of occurence defining rarity among
                  the collection, giving you the chance to own a truly unique
                  and rare Weird Ape.
                                </p>
                                <h3 className='sub-heading'>GENESIS</h3>
                                <p className='para mb-0'>
                  Once upon a time there was a weird ape who, outcast from his
                  tribe, wandered too far into the dark forest of the
                  EtherJungle. The befuddled monkey jumped from tree to tree,
                  until an unusual liana took him into a bizarre place, the
                  CronosJungle. Lost as he was, he screamed his incoherent call
                  for days, until three fellow vagabonds found him. The rest of
                  the story, the story of the first tribe of the CronosJungle,
                  is a tale told in the blocks that followed...
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
