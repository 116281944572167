import About from "../components/About";
import Faq from "../components/Faq";
import Genetics from "../components/Genetics";
import RoadMap from "../components/RoadMap";
// import Team from 'components/Team';
import React, { useEffect /*, useState*/ } from "react";
import HeroSection from "../components/HeroSection";
import Navbar from "../layout/Navbar";

import AOS from "aos";
import "aos/dist/aos.css";

export default function Home() {
  useEffect(() => {
    AOS.init({ once: true });
    // Not a good practice to use JQuery here
    var $ = window.jQuery;
    $("body").removeClass("fix-screen-mobile");
    $(".main-top-section").removeClass("mobile-view");
    $(".hamburger-menu").removeClass("animate");
  }, []);

  return (
    <>
      <main className="main-top-section weird_bg_img position-relative hero-section d-flex flex-column">
        <Navbar />
        <HeroSection />
      </main>
      <About />
      <Genetics />
      <RoadMap />
      {/*<Team />*/}
      <Faq />
    </>
  );
}
