export const actionTypes = {
  // App
  setLoading: "setLoading",
  // Web3
  registerWeb3: "registerWeb3",
  // Contracts
  updateChainId: "updateChainId",
  updateTokenContract: "updateTokenContract",
  updateOldApesContract: "updateOldApesContract",
  updateGenesisApesContract: "updateGenesisApesContract",
  updateVoxelApesContract: "updateVoxelApesContract",
  updateProxyContract: "updateProxyContract",
  // Balance
  updateTokenBalance: "updateTokenBalance",
  updateTokenPendingBalance: "updateTokenPendingBalance",
  // Apes
  updateGenesisApesWallet: "updateGenesisApesWallet",
};
