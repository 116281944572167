import React, { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

export default function Wac() {
  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  return (
    <>
      <section className="my-5 open_overlay-sections_none">
        <div className="container z-index-100">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9">
              <div className="text-center mb-5 pb-4 pb-lg-5">
                <h1 className="main-heading">$WAC Weirdonomics</h1>
                <p className="para px-lg-5">
                  $WAC sits at the core of everything in the Weird Apes Club. As
                  the club grows more utilities will be added. The mission of
                  Weird Apes Club extends further than just a profile picture.
                  It&apos;s about family, utility, and of course the main life
                  source of any Weird Ape, $WAC that lets us fuel our ecosystem
                  on the blockchain.
                </p>
              </div>
            </div>
          </div>
          <div
            className="
          row
          align-items-center
          justify-content-center justify-content-lg-between
        "
          >
            <div className="col-lg-6 mb-5">
              <div className="d-flex align-items-start mb-5">
                <div className="me-4 me-sm-5">
                  <img src="/assets/img/yield-img.png" />
                </div>
                <div>
                  <h1 className="heading">YIELD</h1>
                  <p className="para mb-0">
                    Each Genesis Weird Ape after a day of hard work earns 8 $WAC
                    per day. You can claim $WAC through our DApp.
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-start mb-5">
                <div className="me-4 me-sm-5">
                  <img src="/assets/img/carbon_settings-check-img.png" />
                </div>
                <div>
                  <h1 className="heading">CUSTOMIZE</h1>

                  <p className="para mb-0">
                    Customize your favorite Weird Ape.
                  </p>

                  <p className="para mb-0">
                    It doesn&apos;t matter if it&apos;s a Genesis, Lady or Baby.
                  </p>

                  <p className="para mb-0">
                    Want to change the name of your Weird Ape?
                  </p>

                  <p className="para mb-0">You will have to burn 80 $WAC.</p>
                  <p className="para mb-0">
                    Want your Weird Ape to have a lore?
                  </p>
                  <p className="para mb-0">You will have to burn 160 $WAC.</p>
                </div>
              </div>

              <div className="d-flex align-items-start mb-5">
                <div className="me-4 me-sm-5">
                  <img src="/assets/img/breed.png" />
                </div>
                <div>
                  <h1 className="heading">BREED</h1>
                  <p className="para mb-0">
                    When the Babies are playing in the cronosjungle, the adults
                    take some time for private fun.
                  </p>
                  <p className="para mb-0">Breeding costs 480 $WAC.</p>
                </div>
              </div>

              <div className="d-flex align-items-start mb-5">
                <div className="me-4 me-sm-5">
                  <img src="/assets/img/weirdonite-img.png" />
                  {/*'/assets/img/weirdonite.png' />*/}
                </div>
                <div>
                  <h1 className="heading">PLAY</h1>
                  <p className="para mb-0">
                    By burning $WAC, you can take a Voxel Weird Ape through the
                    Portal of Void to collect Weirdonite and exclusive prizes.
                  </p>
                  <p className="para mb-0">
                    $WAC can be burnt to obtain in-game assets to boost your
                    Voxel.
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-start mb-5">
                <div className="me-4 me-sm-5">
                  <img src="/assets/img/coin-img.png" />
                </div>
                <div>
                  <h1 className="heading">FARM</h1>
                  <p className="para mb-0">
                    Stake your $WAC-CRO LP Tokens to earn additional $WAC!
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-start mb-5">
                <div className="me-4 me-sm-5">
                  <img src="/assets/img/vote-img.png" />
                </div>
                <div>
                  <h1 className="heading">VOTE</h1>
                  <p className="para mb-0">
                    $WAC is both a utility token and a governance token! $WAC
                    provides holders the opportunity to decide, together, on the
                    future direction of our jungle.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-11 col-sm-9 col-md-7 col-lg-5 col-xl-4">
              <div>
                <div className="black-box p-3 p-sm-4 text-center">
                  <p className="para">
                    $WAC is the utility token that fuels the Weird Apes Club
                    ecosystem. It is NOT an investment.
                  </p>
                  <p className="para mb-5 pb-4 pb-lg-5">
                    Each Genesis Weird Apes is able to claim 8 $WAC tokens per
                    day for a period of ten years. Genesis Weird Apes will
                    produce total of 7.300.000 $WAC tokens per year, over a
                    period of ten years.
                  </p>
                  <div className="position-relative main-box-btn pb-5 cursor-pointer">
                    <a
                      href="https://weirdape.gitbook.io/weird-apes-club/usdwac"
                      className="connect-wallet-btn px-4 py-3 text-white z-index-100"
                    >
                      READ MORE
                    </a>
                    <span className="read-btn-img btn-img">
                      <img src="/assets/img/Frame_6.png" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
