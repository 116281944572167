// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call({ from: account });
      let userNFTs = await store
        .getState()
        .blockchain.smartContract.methods.balanceOf(account)
        .call({ from: account });

      let migratedNFTs = await store
        .getState()
        .blockchain.smartContract2.methods.balanceOf(account)
        .call({ from: account });

      dispatch(
        fetchDataSuccess({
          totalSupply,
          userNFTs,
          migratedNFTs,
        })
      );
    } catch (err) {
      console.error(err || "Contract data not loaded. Error is undefined");
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
