import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
// import logger from 'redux-logger';

import blockchainReducer from "./blockchain/blockchainReducer";
import dataReducer from "./data/dataReducer";

const rootReducer = combineReducers({
  blockchain: blockchainReducer,
  data: dataReducer,
});

const middlewares = [thunk];

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === "development") {
  // middlewares.push(logger);
}

const composeEnhancers = compose(applyMiddleware(...middlewares));

const configureStore = () => {
  return createStore(rootReducer, composeEnhancers);
};

const store = configureStore();

export default store;
