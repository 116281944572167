import React from 'react';

export default function AButton({ text, disabled = false, className, noPointer = false,children, ...other }) {
    return (
        <div className="main-box-btn">
            <button
                className={`connect-wallet-btn px-4 py-3 text-white ${noPointer && 'no-cursor'} ${className && className}`}
                // :disabled="disabled"
                disabled={disabled}
                {...other}
            >
                {text}
            </button>
            {children && children}
            {/* <slot /> */}
        </div>
    );
}
